import { MatDialogRef } from "@angular/material/dialog";
import { Component, ViewChild } from "@angular/core";
import { DropzoneComponent, type DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { TranslateService } from "@ngx-translate/core";
import { TransactionsService } from "../../transactions.service";
import saveAs from "file-saver";
import { FormBuilder, Validators } from "@angular/forms";
import { LayoutUtilsService, MessageType } from "../../../../../core/_base/crud";
import { IResponse } from "../../../../../core/_base/crud/interfaces/response-interface";
import { returnErrorDetails } from "../../../../../core/_base/crud/utils/error-utils";
import { ITransactionImportError } from "../../interfaces/transaction-import-error.interface";
import { MatStepper } from "@angular/material/stepper";
import { IParsedTransactionSetSheet } from "../../interfaces/transaction-parsed.interface";
import { transactionTypesLabels } from "../../../enums/transaction/transactionTypes";

@Component({
  selector: "kt-transaction-import-dialog",
  templateUrl: "./transaction-import-dialog.component.html",
  styleUrls: ["./transaction-import-dialog.component.scss"],
})
export class TransactionImportDialogComponent {
  enableHeader: boolean = true;

  @ViewChild("documentsDropzone") dropzoneRef: DropzoneComponent;
  @ViewChild("stepper") matStepper: MatStepper;

  dropZoneConfig: DropzoneConfigInterface = {
    acceptedFiles: ".csv,.xlsx",
    maxFilesize: 60,
    maxFiles: 1,
  };
  attachmentDropzoneConfig: DropzoneConfigInterface = {
    acceptedFiles: ".jpeg,.png,.jpg,.bmp,.gif,.pdf",
    maxFilesize: 60,
    maxFiles: 10,
  };
  dropzoneMessage: string = `
  <div class="dropzone-placeholder">
    <img class="file-icon" src="assets/media/icons/document-grey.png">
    <span class="dropzone-placeholder-text">
      ${this.translate.instant("TRANSACTIONS.ADD.FIELDS.ATTACHMENTS.PLACEHOLDER")}
    </span>
  </div>
`;
  viewLoading = false;
  numOfFiles: number = 0;
  transactionSheetFile = this.formBuilder.control(null);
  importStepValidity = this.formBuilder.control(false, Validators.requiredTrue);
  importErrors: ITransactionImportError[] = [];
  panelOpenState = false;
  parsedTransactionsSets: IParsedTransactionSetSheet[] = [];
  transactionTypesLabels = transactionTypesLabels;
  transactionSetFiles = new Map<number, Blob[]>();

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private layoutService: LayoutUtilsService,
    private transactionService: TransactionsService,
    public dialogRef: MatDialogRef<TransactionImportDialogComponent>
  ) {}

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  importTransactions() {
    this.viewLoading = true;
    const formDataPayload = new FormData();
    formDataPayload.append("transactions_sheet", this.transactionSheetFile.value);
    this.transactionService.importTransactions(formDataPayload).subscribe({
      next: (res) => {
        this.parsedTransactionsSets = res.data;
        this.viewLoading = false;
        this.importErrors = [];
        this.importStepValidity.setValue(true);
        this.matStepper.next();
      },
      error: (err) => {
        const errorDetails = returnErrorDetails(err);
        this.importErrors = (errorDetails.errors as []) ?? [];
        this.viewLoading = false;
        this.importStepValidity.setValue(false);
        errorDetails.message
          ? this.layoutService.showActionNotification(errorDetails.message, MessageType.Delete)
          : this.layoutService.showActionNotification(
              this.translate.instant("GENERAL.SOMETHING_WENT_WRONG"),
              MessageType.Delete
            );
      },
    });
  }

  createTransactions() {
    this.viewLoading = true;
    const formDataPayload = this.prepareCreateFormData();
    this.transactionService.bulkCreateTransactions(formDataPayload).subscribe({
      next: (res) => {
        this.layoutService.showActionNotification(res.message);
        this.onDismiss();
      },
      error: (err) => {
        this.viewLoading = false;
        this.layoutService.showActionNotification(
          this.translate.instant("GENERAL.SOMETHING_WENT_WRONG"),
          MessageType.Delete
        );
      },
    });
  }

  addFile(file: Blob) {
    if (this.numOfFiles === 0) {
      this.transactionSheetFile.setValue(file);
    }
    this.numOfFiles++;
  }

  removeFile() {
    if (this.numOfFiles === 1) {
      this.transactionSheetFile.reset();
      this.importStepValidity.setValue(false);
    }
    this.numOfFiles--;
  }

  addAttachment(file: Blob, transactionSetIndex: number) {
    const fileArray = this.transactionSetFiles.get(transactionSetIndex) ?? [];
    fileArray.push(file);
    this.transactionSetFiles.set(transactionSetIndex, fileArray);
  }

  removeAttachment(file: Blob, transactionSetIndex: number) {
    const fileArray = this.transactionSetFiles.get(transactionSetIndex) ?? [];
    const removedFile = fileArray.indexOf(file);
    if (removedFile > -1) fileArray.splice(removedFile, 1);
    this.transactionSetFiles.set(transactionSetIndex, fileArray);
  }

  downloadTemplate() {
    this.transactionService.downloadImportTemplate().subscribe({
      next: (res: Blob) => {
        saveAs(res, "transactionsTemplate");
      },
    });
  }

  private prepareCreateFormData(): FormData {
    const formDataPayload = new FormData();
    let setIndex = 0;
    for (const transactionSet of this.parsedTransactionsSets) {
      const baseKey = `transaction_set[${setIndex}]`;

      Object.entries(transactionSet).forEach(([key, value]) => {
        if (typeof value === "object" || value === null) return;
        formDataPayload.append(`${baseKey}[${key}]`, value);
      });

      if (this.transactionSetFiles.has(setIndex)) {
        this.transactionSetFiles.get(setIndex).forEach((file, index) => {
          formDataPayload.append(`${baseKey}[documents][${index}][file]`, file);
        });
      }

      transactionSet.transactions.forEach((transaction, index) => {
        Object.entries(transaction).forEach(([key, value]) => {
          if (typeof value === "object" || value === null) return;
          formDataPayload.append(`${baseKey}[transactions][${index}][${key}]`, value);
        });
      });

      Object.entries(transactionSet.contraAccountTransaction).forEach(([key, value]) => {
        if (typeof value === "object" || value === null) return;
        formDataPayload.append(`${baseKey}[contraAccountTransaction][${key}]`, value);
      });

      setIndex++;
    }

    return formDataPayload;
  }
}
