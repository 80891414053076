<div class="kt-portlet">
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-progress">
      <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        {{ "TRANSACTIONS.VIEW.IMPORT_TRANSACTION_MODAL.TITLE" | translate }}
      </h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>
  <div class="kt-form">
    <div class="kt-portlet__body">
      <mat-stepper linear #stepper>
        <mat-step [stepControl]="importStepValidity">
          <ng-template matStepLabel> {{ "TRANSACTIONS.ADD.ACTIONS.UPLOAD_SHEET" | translate }} </ng-template>
          <div class="kt-form__group">
            <div>
              <div class="col-md-8 kt-margin-bottom-10-mobile">
                <mat-hint class="mx-2" align="start">
                  {{ "TRANSACTIONS.ADD.FIELDS.ATTACHMENTS.NAME" | translate }}</mat-hint
                >
                <div class="row dropzone-row">
                  <div class="col-md-10 kt-margin-bottom-10-mobile">
                    <div class="dropzone-wrapper dropzone-borderless dropzone-multi">
                      <dropzone
                        [disabled]="false"
                        [config]="dropZoneConfig"
                        [message]="dropzoneMessage"
                        (removedFile)="removeFile()"
                        (addedFile)="addFile($event)"
                      >
                      </dropzone>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-4">
                <button (click)="downloadTemplate()" class="btn btn-primary mx-2" color="primary" mat-raised-button>
                  <i class="fas fa-download"></i>
                  <span class="kt-nav__link-text">
                    {{ "CONTRACTS.DETAILS.TRANSACTIONS.DOWNLOAD_TEMPLATE" | translate }}
                  </span>
                </button>
              </div>
            </div>
            <div class="mt-4">
              <ul>
                <mat-error *ngFor="let err of importErrors">
                  <li class="py-1" *ngFor="let msg of err.message">
                    <span *ngIf="err.row !== 0"> Row {{ err.row }} - </span>
                    <span *ngIf="err.row === 0"> Sheet 1 - </span>
                    <span> {{ err.column }} - </span>
                    <span> {{ msg }} </span>
                  </li>
                </mat-error>
              </ul>
            </div>
          </div>
          <div class="text-right">
            <button
              (click)="importTransactions()"
              class="btn btn-primary"
              color="primary"
              mat-raised-button
              [disabled]="viewLoading || numOfFiles !== 1"
            >
              {{ "TRANSACTIONS.ADD.ACTIONS.IMPORT" | translate }}
            </button>
          </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel> {{ "TRANSACTIONS.ADD.ACTIONS.UPLOAD_ATTACHMENTS" | translate }}</ng-template>
          <div class="kt-form__group">
            <mat-accordion>
              <ng-container *ngFor="let transactionSet of parsedTransactionsSets; let i = index">
                <mat-expansion-panel [expanded]="false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      #{{ i + 1 }} {{ "TRANSACTIONS.VIEW.TRANSACTION_SET" | translate }} ({{
                        transactionSet.setNumber
                      }})
                    </mat-panel-title>
                    <mat-panel-description>
                      {{ transactionSet.transactions.length + 1 }}
                      {{ transactionTypesLabels.get(transactionSet.type) | translate }}
                      {{ "TRANSACTIONS.VIEW.TITLE" | translate }}, {{ transactionSet.year }}
                      {{ "SETTINGS.JOBS.ECONOMIC_JOBS.ACCOUNTING_YEARS" | translate }},
                      {{ (transactionSet.contractNumber ? "CONTRACTS.VIEW.TITLE" : "VEHICLE.VIEW.TITLE") | translate }}
                      {{ transactionSet.contractNumber ?? transactionSet.vehicleId }}
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <div class="px-2 row">
                      <div class="col-6">
                        <ng-container *ngFor="let transaction of transactionSet.transactions | slice : 0 : 10">
                          <li>
                            <span> {{ transaction.amount }} DKK. </span>
                            <span> {{ transaction.product }}</span>
                            <span *ngIf="transaction.accountVatCode"> [{{ transaction.accountVatCode }}] </span>
                          </li>
                        </ng-container>
                        <li *ngIf="transactionSet.transactions.length > 10">
                          ... {{ transactionSet.transactions.length - 10 }} {{ "TRANSACTIONS.VIEW.TITLE" | translate }}
                        </li>
                        <li class="pt-2 kt-font-primary">
                          <span> {{ transactionSet.contraAccountTransaction.amount }} DKK. </span>
                          <span> {{ transactionSet.contraAccountTransaction.contraAccount }}</span>
                        </li>
                      </div>
                      <div class="col-6">
                        <dropzone
                          [config]="attachmentDropzoneConfig"
                          [message]="dropzoneMessage"
                          (addedFile)="addAttachment($event, i)"
                          (removedFile)="removeAttachment($event, i)"
                        >
                        </dropzone>
                      </div>
                    </div>
                  </ng-template>
                </mat-expansion-panel>
              </ng-container>
            </mat-accordion>
          </div>
          <div class="text-right">
            <button mat-button matStepperPrevious>Back</button>
            <button
              (click)="createTransactions()"
              [disabled]="viewLoading"
              class="btn btn-primary"
              color="primary"
              mat-raised-button
            >
              {{ "TRANSACTIONS.ADD.ACTIONS.CREATE" | translate }}
            </button>
          </div>
        </mat-step>
      </mat-stepper>
    </div>
    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
      <div class="kt-form__actions kt-form__actions--sm">
        <button mat-button (click)="onDismiss()">
          {{ "GENERAL.CANCEL" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
